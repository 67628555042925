// @ts-nocheck
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import { getTranslatedTextByKey, isMenuAutoLoad } from 'utils/utils'

import dynamic from 'next/dynamic'
import { isNextJS } from '../../utils/nextUtils'

import Skeleton from '../components/Skeleton'
const HomeContent = isNextJS()
	? dynamic(() => import('../static/components/home/HomeContent').then((mod) => mod.HomeContent))
	: React.lazy(() => import('../static/components/home/HomeContent'))

export const Home = inject(
	'User',
	'Infra',
	'Home',
	'Payment',
	'AddressManager'
)(
	observer((props) => {
		const { User, Infra, Home: home, location, AddressManager } = props
		const [showCart, setShowCart] = useState(false)

		useEffect(() => {
			setShowCart(AddressManager.isUserLocalized())
		}, [])

		return (
			<Skeleton
				title={getTranslatedTextByKey('webHeaderTitle') || ''}
				showCart={showCart}
				showSearch={false}
				showCustomMessage={false}
				showLogo
				showMenuTabs={false}
				showPaymentMessage={false}
				logoURL={home?.logo?.msg}
				isHome
				isMenuAutoLoad={isMenuAutoLoad(Infra.appParams)}
				headerData={Infra.eCommerceFooter?.header}
				showStoreInfo
			>
				<HomeContent location={location} />
			</Skeleton>
		)
	})
)

export default withRouter(Home)
