// @ts-nocheck
import React, { useEffect, useState } from 'react'
import SwitchHeader from './header/SwitchHeader'
import { inject, observer } from 'mobx-react'
import { isMobile, routeToPage } from '../utils/utils'
import { withDialog } from 'HOCs/withDialog'
import AccountStepsManager from './AccountSteps/AccountStepsManager'
import ECommerceOnly from './common/ECommerceOnly'
import styled from 'styled-components/macro'
import SideMenu from './common/sideMenu/SideMenu'
import BackdropAndLoader from './common/BackdropAndLoader'
import SubHeaderGroup from 'components/header/SubHeaderGroup'
import CartSummary from 'components/cart/CartSummary'
import SlideRoute from 'routing/SlideRoute'
import { CONSTANTS } from 'utils/constants'
import { useCurrentUrlInfo } from 'hooks/useCurrentUrlInfo'
import WithGuard from '../HOCs/withGuard'
import { Guard } from 'mobx/Infra/Infra.type'

const AppContent = styled.div`
	margin-top: ${({ $useOffset }) => ($useOffset ? '70px' : '0')};
`

const StickyHeader = styled.header`
	position: sticky;
	top: 0;
	z-index: 1200;
`

const Skeleton = inject(
	'Infra',
	'Application',
	'AddressManager',
	'User'
)(
	observer((props) => {
		const {
			Infra,
			Application,
			showHeader,
			isCheckout,
			isHome,
			isPageMenu,
			isMenuAutoLoad,
			children,
			headerData,
			User,
			AddressManager,
			isSummary,
			page,
			...headerProps
		} = props

		const [deliveryInfo, setDeliveryInfo] = useState('')
		const [showCartSummary, setShowCartSummary] = useState(false)
		const [isUserLocalized, setIsUserLocalized] = useState(false)
		const [isHydrated, setIsHydrated] = useState(false)
		const { pathname } = useCurrentUrlInfo()
		const desktop = !isMobile()

		const { isMobileApp } = Application
		useEffect(() => {
			setIsHydrated(true)

			setIsUserLocalized(AddressManager.isUserLocalized())
			if (AddressManager.isUserLocalized()) {
				const isDelivery = User.getOrderType() === CONSTANTS.DELIVERY_METHODS.DELIVERY
				setDeliveryInfo(AddressManager.getAddressWhenLocalized(isDelivery))
			}
		}, [])

		useEffect(() => {
			Application.setPage(routeToPage(pathname))
		}, [pathname])

		return (
			<SlideRoute>
				<StickyHeader>
					<SwitchHeader
						{...headerProps}
						isCheckout={isCheckout}
						isHome={isHome}
						$isMobileApp={isMobileApp && !isPageMenu}
						headerData={headerData}
						setShowCartSummary={setShowCartSummary}
						showCartSummary={showCartSummary}
					/>
					{showHeader && (
						<SubHeaderGroup
							showStoreInfo={props.showStoreInfo}
							showMenuTabs={props.showMenuTabs}
							deliveryInfo={deliveryInfo}
							isPageMenu={isPageMenu}
							page={page}
						/>
					)}
				</StickyHeader>

				<WithGuard context={Guard.Context.account} type={Guard.Type.page} name={Guard.Name.account}>
					<AppContent>{children}</AppContent>
				</WithGuard>

				{/* Display CartSummary only when
					- the user is not on checkout page
					- the user is localized (except for chat flow)  */}
				{!isCheckout && (isUserLocalized || User.isChatApp()) && (
					<CartSummary
						isMenu
						isSummary={isSummary}
						isOpen={showCartSummary}
						setShowCartSummary={setShowCartSummary}
						isHover={!isPageMenu && desktop}
					/>
				)}

				{Infra.loading && (
					<BackdropAndLoader
						$isMenuAutoLoad={isMenuAutoLoad}
						open={Infra.loading}
						chainId={Infra.appParams?.c}
						brand={Infra.appParams.brand}
					/>
				)}
				{Infra.displayMobileMenu && <SideMenu headerProps={headerProps} />}
				<ECommerceOnly>
					<AccountStepsManager />
				</ECommerceOnly>
				{isHydrated && <span data-testid="is-hydrated" style={{ display: 'none' }} />}
			</SlideRoute>
		)
	})
)

export default withDialog(Skeleton)
