import { inject, observer } from 'mobx-react'
import type React from 'react'

/**
 * Wrap a component with this component if it meant only to be rendered for an eCommerce client
 *
 * It works by looking for an 'eCommerce' boolean that is only provided by params.json which is only used for
 * eCommerce apps.
 */
const ECommerceOnly = inject('Infra')(
	observer(({ Infra, children }: React.PropsWithChildren<{ Infra: { appParams: { eCommerce: boolean } } }>) => {
		const isEcommerce = Infra.appParams?.eCommerce

		return isEcommerce && children
	})
)

export default ECommerceOnly
