// @ts-nocheck
import React from 'react'
import dynamic from 'next/dynamic'
import { isNextJS } from '../../../utils/nextUtils'
import { useClientSideRendering } from 'hooks/useClientSideRendering'

const BrowserHeader = isNextJS()
	? dynamic(() => import('./headers/BrowserHeader').then((mod) => mod.BrowserHeader))
	: React.lazy(() => import('./headers/BrowserHeader'))

const MobileAppHeader = isNextJS()
	? dynamic(() => import('./headers/MobileAppHeader').then((mod) => mod.MobileAppHeader))
	: React.lazy(() => import('./headers/MobileAppHeader'))

/**
 * This component chooses which header to display
 */
const SwitchHeader = (props) => {
	const isClientSideRendering = useClientSideRendering()

	return isClientSideRendering && (props.$isMobileApp ? <MobileAppHeader {...props} /> : <BrowserHeader {...props} />)
}

export default SwitchHeader
